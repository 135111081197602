import {
  CONTACT_FORM_CLEANUP,
  CONTACT_FORM_UPDATE,
  CONTACT_SET_ERROR
} from "./actions"

const initialState = {
  clientId: null,
  fullname: null,
  email: null,
  topic: null,
  team: null,
  date: null,
  error: null,
  step: 0,
  time: {},
}

function reducer(state = initialState, action) {
  const { data } = { ...action.payload }
  switch (action.type) {
    case CONTACT_FORM_CLEANUP:
      return { ...initialState }

    case CONTACT_FORM_UPDATE:
      return { ...state, ...data }

    case CONTACT_SET_ERROR:
      return { ...state, error: data }

    default:
      return state
  }
}

export default reducer
