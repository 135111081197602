// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-tour-events-js": () => import("./../../../src/pages/tour/events.js" /* webpackChunkName: "component---src-pages-tour-events-js" */),
  "component---src-pages-tour-management-js": () => import("./../../../src/pages/tour/management.js" /* webpackChunkName: "component---src-pages-tour-management-js" */),
  "component---src-pages-tour-payment-js": () => import("./../../../src/pages/tour/payment.js" /* webpackChunkName: "component---src-pages-tour-payment-js" */),
  "component---src-pages-tour-planning-js": () => import("./../../../src/pages/tour/planning.js" /* webpackChunkName: "component---src-pages-tour-planning-js" */),
  "component---src-pages-tour-room-deleted-js": () => import("./../../../src/pages/tour/room/deleted.js" /* webpackChunkName: "component---src-pages-tour-room-deleted-js" */),
  "component---src-pages-tour-room-edit-js": () => import("./../../../src/pages/tour/room/edit.js" /* webpackChunkName: "component---src-pages-tour-room-edit-js" */),
  "component---src-pages-tour-room-index-js": () => import("./../../../src/pages/tour/room/index.js" /* webpackChunkName: "component---src-pages-tour-room-index-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

