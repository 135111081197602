import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import { persistReducer } from "redux-persist"
import { createWhitelistFilter } from "redux-persist-transform-filter"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"

import { subapps } from "@constants/enums"

import { subapp } from "@utils/functions"

import account from "./account/reducer"
import calendars from "./calendars/reducer"
import contact from "./contact/reducer"
import dialogs from "./dialogs/reducer"
import events from "./events/reducer"
import fetchs from "./fetcher/reducer"
import operators from "./operators/reducer"
import options from "./options/reducer"
import requests from "./requests/reducer"
import tenant from "./tenant/reducer"

export const persistWhitelist = [
  createWhitelistFilter("account", ["user"]),
  createWhitelistFilter("options", ["language"]),
  createWhitelistFilter("tenant"),
]

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["account", "requests", "events", "options", "tenant"],
  transforms: persistWhitelist,
  stateReconciler: autoMergeLevel2,
}

const reducersObj = {
  account,
  calendars,
  contact,
  dialogs,
  fetchs,
  options,
  operators,
  requests,
  events,
  tenant,
}

switch (subapp) {
  case subapps.HELP:
    delete reducersObj.events
    break
  case subapps.TOUR:
    delete reducersObj.requests
    break
}

const rootReducer = combineReducers(reducersObj)

const persistedReducers = persistReducer(persistConfig, rootReducer)
const isWindow =
  typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
const composeEnhancers = isWindow
  ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose
  : compose

const store = isWindow
  ? createStore(persistedReducers, {}, composeEnhancers(applyMiddleware(thunk)))
  : createStore(persistedReducers, compose(applyMiddleware(thunk)))

export default store
