export const CONTACT_FORM_CLEANUP = "CONTACT_FORM_CLEANUP"
export const CONTACT_FORM_UPDATE = "CONTACT_FORM_UPDATE"
export const CONTACT_SET_ERROR = "CONTACT_SET_ERROR"

export const contactFormCleanup = () => ({ type: CONTACT_FORM_CLEANUP })

export const contactFormUpdate = data => ({
  type: CONTACT_FORM_UPDATE,
  payload: { data },
})

export const contactSetError = data => ({
  type: CONTACT_SET_ERROR,
  payload: { data },
})
